import styled from "styled-components";
import { Palette } from "../../../../../theme/Palette";

export const Root = styled.div`
    display: grid;
    grid-template-rows: max-content auto;
    align-items: center;
    grid-row-gap: 16px;
    max-width: 100%;
    justify-content: stretch;
`;

export const Heading = styled.h2`
    text-transform: uppercase;
    color: ${Palette.Neutral60};
    font-size: 14px;
    font-weight: 700;
`;

export const BlocksWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 32px;
    height: 100%;
`;

export const LinkBlock = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${Palette.Neutral0};
    border: solid 1px ${Palette.Neutral20};
    border-radius: 4px;
    padding: 24px;
    text-decoration: none;
    transition: transform 200ms ease-in-out;

    :hover {
        transform: scale(1.025);
    }
`;

export const ContentContainer = styled.div`
    display: grid;
    max-height: 320px;
    grid-template-rows: auto auto auto;
    grid-row-gap: 16px;
    justify-items: center;
    align-items: center;
    text-align: center;
`;

export const BlockHeading = styled.span`
    font-size: 16px;
    font-weight: 700;
    color: ${Palette.Neutral100};
`;

export const BlockText = styled.span`
    color: ${Palette.Neutral60};
`;
