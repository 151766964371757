import * as React from "react";
import {
    Root,
    CheckedIcon,
    UncheckedIcon,
    Label,
    LinkWrapper,
    LinkLabel,
    FocusedRoot,
    FocusedLabel,
    FocusedLinkLabel,
    IconContainerOne,
    IconContainerTwo,
    IconContainerThree,
} from "./ChecklistItem.styles";

interface IStyleProps {
    readonly isComplete?: boolean;
    readonly itemLabel: string;
    readonly itemLinkLabel: string;
    readonly itemLink: string;
    readonly isFocused?: boolean;
}

export const ChecklistItem: React.FC<React.PropsWithChildren<IStyleProps>> = ({
    itemLabel,
    itemLinkLabel,
    itemLink,
    isComplete = false,
    isFocused = false,
}) => {
    if (isFocused) {
        return (
            <LinkWrapper to={itemLink}>
                <FocusedRoot isComplete={isComplete}>
                    {isComplete ? <CheckedIcon /> : <UncheckedIcon />}
                    <FocusedLabel isComplete={isComplete}>{itemLabel}</FocusedLabel>
                    <FocusedLinkLabel>{itemLinkLabel}</FocusedLinkLabel>
                    <IconContainerOne></IconContainerOne>
                    <IconContainerTwo></IconContainerTwo>
                    <IconContainerThree></IconContainerThree>
                </FocusedRoot>
            </LinkWrapper>
        );
    }
    return (
        <LinkWrapper to={itemLink}>
            <Root isComplete={isComplete}>
                {isComplete ? <CheckedIcon /> : <UncheckedIcon />}
                <Label isComplete={isComplete}>{itemLabel}</Label>
                <LinkLabel>{itemLinkLabel}</LinkLabel>
            </Root>
        </LinkWrapper>
    );
};
