import styled, { keyframes } from "styled-components";
import checked from "./checked.svg";
import round from "./round.svg";
import { Palette } from "../../theme/Palette";
import { Link } from "gatsby";
import SparkleIcon from "./sparkle-vector.svg";

interface IStyleProps {
    readonly isComplete?: boolean;
}

const throbbing = keyframes`
    0% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.9);
    }
`;

export const Root = styled.div<IStyleProps>`
    display: grid;
    grid-template-columns: 24px auto minmax(auto, 160px);
    grid-column-gap: 16px;
    justify-items: stretch;
    align-items: center;
    background-color: ${Palette.Neutral10};
    border: 1px solid ${Palette.Neutral20};
    border-radius: 4px;
    transition: background-color 200ms ease-in;
    transition: transform 200ms ease-in-out;
    padding: 16px 24px;
    margin-bottom: 8px;

    :hover {
        background-color: ${Palette.Neutral20};
        transform: scale(1.01);
    }
`;

export const FocusedRoot = styled.div<IStyleProps>`
    position: relative;
    display: grid;
    grid-template-columns: 24px auto minmax(auto, 160px);
    grid-column-gap: 16px;
    justify-items: stretch;
    align-items: center;
    background: linear-gradient(90deg, ${Palette.Primary100}, ${Palette.Accent100} 100%);
    border-radius: 4px;
    transition: background-color 200ms ease-in;
    transition: transform 200ms ease-in-out;
    padding: 16px 24px;
    margin-bottom: 8px;
    color: ${Palette.White100};
    box-shadow: 0px 0px 8px ${Palette.Neutral30};
    transform: scale(1.01);

    :hover {
        transform: scale(1.02);
    }
`;

export const IconContainerOne = styled.div`
    position: absolute;
    top: 4px;
    left: 6px;
    animation: ${throbbing} 2s linear infinite;
    opacity: 50%;
    background-image: url(${SparkleIcon});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
    width: 16px;
    height: 16px;
`;

export const IconContainerTwo = styled.div`
    position: absolute;
    bottom: 0px;
    left: 55%;
    animation: ${throbbing} 1.5s ease-in-out infinite;
    opacity: 50%;
    background-image: url(${SparkleIcon});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
    width: 24px;
    height: 24px;
`;

export const IconContainerThree = styled.div`
    position: absolute;
    top: 6px;
    left: 70%;
    animation: ${throbbing} 2s linear infinite;
    opacity: 50%;
    background-image: url(${SparkleIcon});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
    width: 20px;
    height: 20px;
`;

export const Label = styled.div<IStyleProps>`
    color: ${Palette.Neutral90};
    font-weight: 700;
    ${({ isComplete }) => isComplete && `text-decoration: line-through;`}
    ${({ isComplete }) => isComplete && `color: ${Palette.Neutral40}`}
`;

export const FocusedLabel = styled.div<IStyleProps>`
    font-weight: 700;
    ${({ isComplete }) => isComplete && `text-decoration: line-through;`}
    ${({ isComplete }) => isComplete && `color: ${Palette.Neutral40}`}
`;

export const CheckedIcon = styled.div`
    display: inline;
    margin-top: 2px;
    width: 24px;
    height: 24px;
    background-image: url(${checked});
`;

export const UncheckedIcon = styled.div`
    display: inline;
    margin-top: 2px;
    width: 24px;
    height: 24px;
    background-image: url(${round});
`;

export const LinkLabel = styled.div`
    color: ${Palette.Neutral90};
    text-align: right;
`;

export const FocusedLinkLabel = styled.div`
    text-align: right;
`;

export const LinkWrapper = styled(Link)`
    cursor: pointer;
    text-decoration: none;
`;
