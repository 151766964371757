import * as React from "react";
import { Root, Heading, BlocksWrapper, BlockHeading, BlockText, LinkBlock, ContentContainer } from "./QuickLinks.styles";
import { TelemetryLink } from "../../../../TelemetryLink/TelemetryLink";
import { Constants } from "../../../../../utilities/Constants";
import { useTranslation } from "react-i18next";
import BookmarkIcon from "./bookmark.svg";
import LightbulbIcon from "./idea.svg";
import SpeechIcon from "./speech-bubble.svg";

export const QuickLinks: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <Heading>{t("dashboard.quickLinks")}</Heading>
            <BlocksWrapper>
                <TelemetryLink href={Constants.BomberlandStarterKits} styledOverride={LinkBlock}>
                    <ContentContainer>
                        <img src={LightbulbIcon} alt={t("dashboard.starterKits")} />
                        <BlockHeading>{t("dashboard.starterKits")}</BlockHeading>
                        <BlockText>{t("dashboard.starterKitsDescription")}</BlockText>
                    </ContentContainer>
                </TelemetryLink>
                <TelemetryLink href={Constants.DiscordLink} styledOverride={LinkBlock}>
                    <ContentContainer>
                        <img src={SpeechIcon} alt={t("dashboard.joinCommunity")} />
                        <BlockHeading>{t("dashboard.joinCommunity")}</BlockHeading>
                        <BlockText>{t("dashboard.joinCommunityDescription")}</BlockText>
                    </ContentContainer>
                </TelemetryLink>
                <TelemetryLink href={Constants.Season4BlogLink} styledOverride={LinkBlock}>
                    <ContentContainer>
                        <img src={BookmarkIcon} alt={t("dashboard.competitionDetails")} />
                        <BlockHeading>{t("dashboard.competitionDetails")}</BlockHeading>
                        <BlockText>{t("dashboard.competitionDetailsDescription")}</BlockText>
                    </ContentContainer>
                </TelemetryLink>
            </BlocksWrapper>
        </Root>
    );
};
