import * as React from "react";
import { H2 } from "../../../../H2/H2";
import { TelemetryLink } from "../../../../TelemetryLink/TelemetryLink";
import { Constants } from "../../../../../utilities/Constants";
import { Root, Subtext, IntextLink } from "./Updates.styles";
import { DiscordButton } from "../../../../DiscordButton/DiscordButton";
import { RouterLink } from "../../../../RouterLink/RouterLink";
import { CoderOneRoute } from "../../../../Pages/CoderOneRoute";

export const Updates: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <Root>
            <div>
                <H2>Congratulations to Igglysplat: our Season 4 grand champions! 👑</H2>
                <Subtext>
                    The Bomberland environment is now open in Playground Mode. You can make new submissions to the leaderboard. Check the{" "}
                    <RouterLink href={CoderOneRoute.GettingStarted}>
                        <IntextLink as="div">Getting Started</IntextLink>
                    </RouterLink>{" "}
                    documentation to make your first submission, and{" "}
                    <TelemetryLink href={Constants.DiscordLink} styledOverride={IntextLink}>
                        join our Discord
                    </TelemetryLink>{" "}
                    to meet the community and keep up-to-date on announcements. <br />
                </Subtext>
            </div>
            <DiscordButton style="inverse" />
        </Root>
    );
};
