import styled from "styled-components";
import { Palette } from "../../../../../theme/Palette";

export const Root = styled.div`
    background-color: ${Palette.Primary100};
    color: ${Palette.Neutral0};
    border-radius: 4px;
    display: grid;
    grid-template-columns: auto max-content;
    align-items: center;
    grid-column-gap: 32px;
    padding: 36px;
    max-width: 100%;
    grid-column: span 2;
`;

export const Subtext = styled.div`
    color: ${Palette.White80};
    padding-top: 8px;
    line-height: 1.6;
`;

export const IntextLink = styled.a`
    color: ${Palette.Neutral0};
    font-weight: 700;
    text-decoration: none;
    display: inline;

    :hover {
        color: ${Palette.Neutral10};
    }
`;

export const InverseButton = styled.a`
    -webkit-box-direction: normal;
    -webkit-text-size-adjust: 100%;
    background-color: transparent;
    border-radius: 4px;
    border: solid 1px ${Palette.Neutral0};
    box-sizing: border-box;
    color: ${Palette.Neutral0};
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.1s;

    &:hover {
        background-color: ${Palette.White10};
    }
`;
