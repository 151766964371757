import * as React from "react";
import { useMemo } from "react";
import { EventCode } from "@coderone/library";
import { H2 } from "../../../../H2/H2";
import { ChecklistItem } from "../../../../ChecklistItem/ChecklistItem";
import { ContentCard } from "../../../../ContentCard/ContentCard";
import { CoderOneRoute } from "../../../../Pages/CoderOneRoute";
import { useTranslation } from "react-i18next";
import { useTeam } from "../../../../../hooks/api/team/useTeam";
import { ProgressBar } from "../../../../ProgressBar/ProgressBar";
import { HeadingContainer, ChecklistItemsContainer } from "./GettingStartedChecklist.styles";
import { useTicket } from "../../../../../hooks/api/ticket/useTicket";

export const GettingStartedChecklist: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const { data: teamData } = useTeam();
    const bomberlandTicket = useTicket(EventCode.BomberlandV4);

    const isTeamItemComplete = useMemo(() => {
        return teamData !== null;
    }, [teamData]);

    const isMember = useMemo(() => {
        return bomberlandTicket.data !== null;
    }, [bomberlandTicket]);

    // TODO
    const isProfileComplete = false;

    const progress = useMemo(() => {
        if (isTeamItemComplete && isMember) {
            return 66;
        } else if (isTeamItemComplete || isMember) {
            return 33;
        } else {
            return 3;
        }
    }, [isTeamItemComplete, isMember]);

    return (
        <ContentCard maxWidth="100%" gridRowGap="0px" padding="8px">
            <HeadingContainer>
                <H2>{t("gettingStarted.welcome")}</H2>
                <ProgressBar progress={progress} />
            </HeadingContainer>
            <ChecklistItemsContainer>
                <ChecklistItem
                    isComplete={isMember}
                    itemLabel={t("gettingStarted.getEntryTicket")}
                    itemLink={CoderOneRoute.Pricing}
                    itemLinkLabel={t("gettingStarted.entryTicketPage")}
                    isFocused={!isMember}
                />
                <ChecklistItem
                    isComplete={isTeamItemComplete}
                    itemLabel={t("gettingStarted.createYourTeam")}
                    itemLink={CoderOneRoute.Team}
                    itemLinkLabel={t("gettingStarted.teamsPage")}
                />
                <ChecklistItem
                    isComplete={isProfileComplete}
                    itemLabel={t("gettingStarted.completeProfile")}
                    itemLink={CoderOneRoute.Account}
                    itemLinkLabel={t("gettingStarted.profilePage")}
                />
            </ChecklistItemsContainer>
        </ContentCard>
    );
};
