import styled from "styled-components";
import { Palette } from "../../theme/Palette";

interface IStyleProps {
    readonly progress?: number;
}

export const Root = styled.div`
    width: 100%;
    height: 24px;
    background: ${Palette.Neutral20};
    border-radius: 12px;
    overflow: hidden;
`;

export const Bar = styled("div")<IStyleProps>`
    width: ${(props) => props.progress}%;
    display: block;
    height: 100%;
    background: linear-gradient(90deg, ${Palette.Success100}, ${Palette.Success10});
    background-size: 300% 100%;
`;
