import styled from "styled-components";

export const HeadingContainer = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    grid-row-gap: 24px;
    margin: 24px;
`;

export const ChecklistItemsContainer = styled.div`
    margin: 24px;
    margin-top: 0px;
`;
