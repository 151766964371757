import * as React from "react";
import { Root, Bar } from "./ProgressBar.styles";

interface IStyleProps {
    readonly progress?: number;
}

export const ProgressBar: React.FC<React.PropsWithChildren<IStyleProps>> = ({ progress }) => {
    return (
        <Root>
            <Bar progress={progress}></Bar>
        </Root>
    );
};
