import * as React from "react";
import { Root } from "./DashboardContent.styles";
import { GettingStartedChecklist } from "./GettingStartedChecklist/GettingStartedChecklist";
import { Updates } from "./Updates/Updates";
import { QuickLinks } from "./QuickLinks/QuickLinks";

interface IProps {
    readonly showUpdates?: boolean;
}

export const DashboardContent: React.FC<React.PropsWithChildren<IProps>> = ({ showUpdates = true }) => {
    return (
        <Root>
            {showUpdates && <Updates />}
            <GettingStartedChecklist />
            <QuickLinks />
        </Root>
    );
};
